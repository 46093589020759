import React from "react";

const PageTitleOne = ({ title, image }) => (
  <div className="transition-none">
    <section
      className="title-hero-bg"
      style={{ backgroundImage: `url(${image})` }}
    >
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="page-title text-center white-color">
              <h1 style={{ fontSize: "2.75em" }} className="font-700">
                {title}
              </h1>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
);

export default PageTitleOne;
