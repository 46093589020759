import React from "react";
// DATA Files
import dataBlog from "../../data/Blog/blog-data.json";
// Images
import imgTitle from "../../assets/images/title-bg/sheroes-bg.jpg";
// Components
import PageTitleOne from "../../components/PageTitle/PageTitleOne";
import BlogContent from "../../components/Blog/BlogContent";
import FooterTwo from "../../components/Footer/FooterTwo";

const post = {
  id: 2,
  image: "post/post-02.jpg",
  type: "slide",
  description: "Post 02",
  slides: ["post/fortune.jpg"],
  published: "December 13, 2021",
  title:
    "Think you can take on Captain America all day long? Or best the mighty Thor?",
  text: "Don't forget the character that single handedly conquered Iron Man without ever shooting lasers from their hands or doing a kick-flip!",
  tags: ["Design", "Branding", "Stationery", "Development", "Concept"],
  comments: [
    {
      id: 1,
      image: "team/avatar-1.jpg",
      title: "caseykix909",
      text: "lol. my gf got aunt may, and I got she-hulk. her *** is mine! gonna smash that *****",
      reply: [
        {
          id: 2,
          image: "team/avatar-3.jpg",
          title: "outfoxd",
          text: "Aunt May in the SpiderMan movies is hot.  U lucky!",
        },
      ],
    },
    {
      id: 1,
      image: "team/avatar-2.jpg",
      title: "menardy12",
      text: "I cheated and it looks like the best yuo can do is Captian Marvel??? I think Scarlet Witch is more powerful.",
      reply: {},
    },
  ],
};

const BlogDetailsPage = () => (
  <>
    <PageTitleOne
      title="Quiz: Which Superheroine Are You... In Bed?"
      image={imgTitle}
    />
    <BlogContent post={post} data={dataBlog} />
    <FooterTwo />
  </>
);

export default BlogDetailsPage;
