import React from "react";
import { useState } from "react";

const Question = ({ id, text, updateRatings }) => {
  const [isChecked, setIsChecked] = useState(false);

  const handleOnChange = () => {
    setIsChecked(!isChecked);
    if (updateRatings) {
      updateRatings(id, !isChecked);
    }
  };

  return (
    <div className="transition-none">
      <div className="container">
        <div className="row">
          <table>
            <tbody>
              <tr style={{ borderBottom: "1px solid white" }}>
                <td
                  style={{
                    width: "40px",
                    verticalAlign: "top",
                    padding: "10px",
                  }}
                >
                  <input
                    type="checkbox"
                    id={id}
                    name={id}
                    value={id}
                    checked={isChecked}
                    onChange={handleOnChange}
                  />
                </td>
                <td style={{ padding: "10px" }}>{text}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Question;
