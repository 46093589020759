import React from "react";
import LoadScript from "../../helpers/LoadScript";
import logo from "../../assets/images/logo-footer.png";
import Icofont from "react-icofont";

const Footer = () => {
  LoadScript("./js/master.js");
  return (
    <>
      <footer className="footer">
        <div className="footer-main">
          <div className="container">
            <div className="row">
              <div className="col-sm-4 col-md-4">
                <div className="widget widget-text">
                  <div
                    className="logo-footer"
                    style={{ display: "inline-block" }}
                  >
                    <a href={process.env.PUBLIC_URL}>
                      <img className="img-responsive" src={logo} alt="" />
                    </a>
                  </div>
                  <div style={{ display: "inline-block", color: "white" }}>
                    © 2022 Domain Wars Dot Net. All Rights Reserved. An elite
                    fanbase publisher.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <a href="!#" id="return-to-top">
        <Icofont icon="arrow-up" />
      </a>
    </>
  );
};

export default Footer;
