import React from "react";
import { useState } from "react";
import Slider from "react-slick";
import BlogSidebar from "../Sidebar/BlogSidebar";
import Skillbar from "../Skillbar/Skillbar";
import Question from "../Questionnaire/Question";
import imgBg from "../../assets/images/background/marvel-bg.png";
import imgCe from "../../assets/images/post/chris-evans.jpg";
import imgBw from "../../assets/images/post/black-widow.jpg";
import icon from "../../assets/images/icons/comics-icon.png";
import imgStAd from "../../assets/images/ads/stranger-things.png";
import imgDrAd from "../../assets/images/ads/dr-strange.png";
import imgWdAd from "../../assets/images/ads/walking-dead.png";

const BlogContent = ({ post, data }) => {
  const settings = {
    dots: false,
    infinite: true,
    centerMode: true,
    autoplay: true,
    autoplaySpeed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerPadding: "0",
    className: "blog-grid-slider slick",
  };

  const [strengthRating, setStrengthRating] = useState(0);
  const [agilityRating, setAgilityRating] = useState(0);
  const [toughnessRating, setToughnessRating] = useState(0);
  const [teamworkRating, setTeamworkRating] = useState(0);
  const [magicRating, setMagicRating] = useState(0);
  const [technologyRating, setTechnologyRating] = useState(0);

  let ratings = {};

  const updateRatings = (id, isChecked) =>
    (ratings = Object.assign({}, ratings, { [id]: isChecked }));

  const calculatePowerRatings = () => {
    let strength = 10;
    let toughness = 10;
    let agility = 10;
    let teamwork = 0;
    let magic = 0;
    let technology = 10;

    if (ratings["origin.climaxed"]) {
      magic += 5;
    }

    if (ratings["origin.penetrated"]) {
      toughness += 3;
      teamwork += 2;
    }

    if (ratings["origin.gaveoral"]) {
      agility += 3;
      teamwork += 2;
    }

    if (ratings["origin.gotoral"]) {
      teamwork += 4;
      magic += 1;
    }

    if (ratings["origin.multiple"]) {
      teamwork += 5;
    }

    setStrengthRating(strength);
    setToughnessRating(toughness);
    setAgilityRating(agility);
    setTeamworkRating(teamwork);
    setMagicRating(magic);
    setTechnologyRating(technology);
  };

  return (
    <section className="white-bg">
      <div className="container">
        <div className="row">
          <div className="col-md-9 col-sm-9 col-xs-12 xs-mb-50">
            <div className="row">
              <div className="col-md-12 col-sm-12 col-xs-12 mb-20 xs-mb-50">
                <div className="post">
                  {post.type === "slide" ? (
                    <Slider {...settings}>
                      {post.slides.map((image, i) => (
                        <div className="item" key={i}>
                          <img
                            className="img-responsive"
                            src={require("../../assets/images/" + image)}
                            alt=""
                          />
                        </div>
                      ))}
                    </Slider>
                  ) : (
                    <img
                      src={require("../../assets/images/" + post.image)}
                      alt=""
                    />
                  )}
                  <div className="post-metas">
                    <div className="post-metas-center">
                      <p className="post-date">{post.published}</p>
                    </div>
                  </div>
                  <div className="post-info all-padding-20">
                    <h3>
                      <a href={process.env.PUBLIC_URL}>{post.title}</a>
                    </h3>
                    <p>{post.text}</p>
                  </div>
                </div>
              </div>
              <div className="col-md-12 col-sm-12 col-xs-12 mb-20">
                <div className="blog-standard">
                  <p>
                    Have you ever wondered how Pepper Potts snared Iron Man and
                    billionaire playboy into a monogomous relationship? I think
                    I know (wink-wink).
                  </p>
                  <blockquote>
                    Have you ever wondered how Pepper Potts snared Iron Man and
                    billionaire playboy Tony Stark into a monogomous
                    relationship?
                  </blockquote>
                  <p>
                    Let's face it, we all want to be superheroines with awesome
                    powers. Maybe you already do though... you just need the
                    right "encounter" to prove it.
                  </p>
                  <hr />
                  <img src={imgCe} alt="go all day long" />
                  <hr />
                  <p>
                    (Or maybe you'd prefer to find out what is under the skin
                    tight suits of a bad ass babe like Black Widow...)
                  </p>
                  <hr />
                  <img src={imgBw} alt="what's underneath" />
                  <hr />
                  <p>
                    For those of you that don't get the{" "}
                    <a
                      href="https://www.urbandictionary.com/define.php?term=fortune%20cookie%20game"
                      style={{ color: "blue" }}
                    >
                      fortune cookie picture and reference
                    </a>
                    , it's a really lame game from the 70's (or something like
                    that). Basically you add the words "in bed" to the end of
                    your fortune cookie message and read it outloud for your
                    friends' amusement. Admittedly, I still play it because I'm
                    thirteen years old in my head.
                  </p>
                  <p>
                    That fortune cookie game gave me the inspiration for
                    something fun. Naughty, but fun.{" "}
                    <i>
                      How about a NSFW questionnaire to reveal your comic book
                      identity... in bed?
                    </i>{" "}
                  </p>
                  <h4>
                    <i>Avengers Assemble!</i>
                  </h4>
                  <section
                    style={{
                      background: `url(${imgBg}) center center #fff`,
                    }}
                    id="about"
                  >
                    <div className="container">
                      <i>
                        Instructions: choose all that apply in each category.
                      </i>
                      <h4>
                        <img
                          src={icon}
                          alt="logo"
                          style={{ height: "50px", marginRight: "10px" }}
                        />
                        Origin Story
                        <span style={{ float: "right", fontSize: "66%" }}>
                          1 of 8
                        </span>
                      </h4>
                      <Question
                        id="origin.climaxed"
                        text="I climaxed in my first encounter"
                        updateRatings={updateRatings}
                      />
                      <Question
                        id="origin.penetrated"
                        text="My partner penetrated me with something larger than a finger during my first encounter"
                        updateRatings={updateRatings}
                      />
                      <Question
                        id="origin.gaveoral"
                        text="I performed oral sex during my first encounter"
                        updateRatings={updateRatings}
                      />
                      <Question
                        id="origin.gotoral"
                        text="I received oral sex in my first encounter"
                        updateRatings={updateRatings}
                      />
                      <Question
                        id="origin.multiple"
                        text="I had multiple encounters the same week as my first one"
                        updateRatings={updateRatings}
                      />
                      <h4>
                        <img
                          src={icon}
                          alt="logo"
                          style={{ height: "50px", marginRight: "10px" }}
                        />
                        Splash Pages
                        <span style={{ float: "right", fontSize: "66%" }}>
                          2 of 8
                        </span>
                      </h4>
                      <Question
                        id="splash.quickie"
                        text="I've engaged in a quickie to get a partner off"
                        updateRatings={updateRatings}
                      />
                      <Question
                        id="splash.toys"
                        text="My partner has used toys on me during an encounter"
                        updateRatings={updateRatings}
                      />
                      <Question
                        id="splash.lingerie"
                        text="I've worn lingerie, costumes or restraints during an encounter"
                        updateRatings={updateRatings}
                      />
                      <Question
                        id="splash.anal"
                        text="I have performed or experienced spontaneous anal penetration or analingus"
                        updateRatings={updateRatings}
                      />
                      <Question
                        id="splash.clothed"
                        text="I have had sex with a partner without removing any clothing"
                        updateRatings={updateRatings}
                      />
                      <h4>
                        <img
                          src={icon}
                          alt="logo"
                          style={{ height: "50px", marginRight: "10px" }}
                        />
                        Fighting Style
                        <span style={{ float: "right", fontSize: "66%" }}>
                          3 of 8
                        </span>
                      </h4>
                      <Question
                        id="style.bottom"
                        text="I've climaxed while on the bottom"
                        updateRatings={updateRatings}
                      />
                      <Question
                        id="style.reversecowgirl"
                        text="I've straddled my partner facing away from them"
                        updateRatings={updateRatings}
                      />
                      <Question
                        id="style.doggie"
                        text="I've been on all fours with one or more partners behind me"
                        updateRatings={updateRatings}
                      />
                      <Question
                        id="style.dominated"
                        text="I've been on all fours with one or more partners in front of me"
                        updateRatings={updateRatings}
                      />
                      <Question
                        id="style.bellydown"
                        text="I have had sex with a partner while on my belly"
                        updateRatings={updateRatings}
                      />
                      <h4>
                        <img
                          src={icon}
                          alt="logo"
                          style={{ height: "50px", marginRight: "10px" }}
                        />
                        Narration
                        <span style={{ float: "right", fontSize: "66%" }}>
                          4 of 8
                        </span>
                      </h4>
                      <Question
                        id="oral.hands"
                        text="During oral sex, my hands work most of the magic"
                        updateRatings={updateRatings}
                      />
                      <Question
                        id="oral.swallow"
                        text="My partner has reached climax while I was performing oral sex"
                        updateRatings={updateRatings}
                      />
                      <Question
                        id="oral.knees"
                        text="I've performed oral sex while on my knees"
                        updateRatings={updateRatings}
                      />
                      <Question
                        id="oral.roadhead"
                        text="I've performed oral sex on the driver of a moving car"
                        updateRatings={updateRatings}
                      />
                      <Question
                        id="oral.public"
                        text="I've given oral sex to a partner in a public or outdoor space"
                        updateRatings={updateRatings}
                      />
                      <h4>
                        <img
                          src={icon}
                          alt="logo"
                          style={{ height: "50px", marginRight: "10px" }}
                        />
                        Heroic Struggles
                        <span style={{ float: "right", fontSize: "66%" }}>
                          5 of 8
                        </span>
                      </h4>
                      <Question
                        id="struggle.long"
                        text="I've had something eight or more inches long inside me"
                        updateRatings={updateRatings}
                      />
                      <Question
                        id="struggle.thick"
                        text="I've had something as big around as my wrist inside me"
                        updateRatings={updateRatings}
                      />
                      <Question
                        id="struggle.sore"
                        text="I've been sore for two or more days from an encounter"
                        updateRatings={updateRatings}
                      />
                      <Question
                        id="struggle.gagged"
                        text="I've choked or gagged during an encounter"
                        updateRatings={updateRatings}
                      />
                      <Question
                        id="struggle.anal"
                        text="A partner has climaxed while taking me anally"
                        updateRatings={updateRatings}
                      />
                      <h4>
                        <img
                          src={icon}
                          alt="logo"
                          style={{ height: "50px", marginRight: "10px" }}
                        />
                        Team Dynamics
                        <span style={{ float: "right", fontSize: "66%" }}>
                          6 of 8
                        </span>
                      </h4>
                      <Question
                        id="team.three"
                        text="I've had sex with a partner three or more times in the same week"
                        updateRatings={updateRatings}
                      />
                      <Question
                        id="team.nudes"
                        text="I have nudes of a partner on my phone or they have nudes of me"
                        updateRatings={updateRatings}
                      />
                      <Question
                        id="team.multi"
                        text="I've had multiple partners at once"
                        updateRatings={updateRatings}
                      />
                      <Question
                        id="team.porn"
                        text="While with a partner, I've seen other people having sex (including porn)"
                        updateRatings={updateRatings}
                      />
                      <Question
                        id="team.undress"
                        text="I've allowed a partner to undress me"
                        updateRatings={updateRatings}
                      />
                      <h4>
                        <img
                          src={icon}
                          alt="logo"
                          style={{ height: "50px", marginRight: "10px" }}
                        />
                        Solo Adventures
                        <span style={{ float: "right", fontSize: "66%" }}>
                          7 of 8
                        </span>
                      </h4>
                      <Question
                        id="solo.three"
                        text="I've masturbated three or more consecutive days"
                        updateRatings={updateRatings}
                      />
                      <Question
                        id="solo.vibrator"
                        text="I've masturbated with a vibrator while sitting or on my belly"
                        updateRatings={updateRatings}
                      />
                      <Question
                        id="solo.large"
                        text="I've masturbated with an object that wasn't a sex toy"
                        updateRatings={updateRatings}
                      />
                      <Question
                        id="solo.bicurious"
                        text="I've masturbated thinking about each gender at least once"
                        updateRatings={updateRatings}
                      />
                      <Question
                        id="solo.wearable"
                        text="I have worn a plug, vibe, Kegel balls or beads under my regular clothing"
                        updateRatings={updateRatings}
                      />
                      <h4>
                        <img
                          src={icon}
                          alt="logo"
                          style={{ height: "50px", marginRight: "10px" }}
                        />
                        Plot Twists
                        <span style={{ float: "right", fontSize: "66%" }}>
                          8 of 8
                        </span>
                      </h4>
                      <Question
                        id="plot.multi"
                        text="I have climaxed multiple times in the same day"
                        updateRatings={updateRatings}
                      />
                      <Question
                        id="plot.nooner"
                        text="I have had an encounter between 10am and 2pm (a nooner)"
                        updateRatings={updateRatings}
                      />
                      <Question
                        id="plot.shower"
                        text="I have had an encounter in the shower"
                        updateRatings={updateRatings}
                      />
                      <Question
                        id="plot.location"
                        text="I have had an encounter at a place of business or gathering"
                        updateRatings={updateRatings}
                      />
                      <Question
                        id="plot.facial"
                        text="A partner has gotten their bodily fluids on my face intentionally"
                        updateRatings={updateRatings}
                      />
                      <hr />
                      <button
                        className="btn btn-color btn-md btn-default remove-margin"
                        onClick={calculatePowerRatings}
                      >
                        Calculate Power Ratings
                      </button>
                      <hr />
                      <div className="col-md-6 col-sm-6 col-xs-12">
                        <div className="skillbar-wrap skillbar-style-02">
                          <Skillbar
                            key="sk1"
                            title={"Strength"}
                            percentage={strengthRating}
                          />
                          <Skillbar
                            key="sk2"
                            title={"Toughness"}
                            percentage={toughnessRating}
                          />
                          <Skillbar
                            key="sk3"
                            title={"Agility"}
                            percentage={agilityRating}
                          />
                          <Skillbar
                            key="sk4"
                            title={"Teamwork"}
                            percentage={teamworkRating}
                          />
                          <Skillbar
                            key="sk5"
                            title={"Magic"}
                            percentage={magicRating}
                          />
                          <Skillbar
                            key="sk6"
                            title={"Technology"}
                            percentage={technologyRating}
                          />
                        </div>
                      </div>
                      <hr />
                    </div>
                  </section>
                </div>
                <h2 className="comment-reply-title mt-30">
                  {post.comments.length + " Comments"}
                </h2>
                <ul className="comment-box">
                  {post.comments.length > 0
                    ? post.comments.map((comment) => (
                        <li
                          className="post-comment"
                          key={Math.random(0, 10000)}
                        >
                          <div className="comment-content">
                            <a href={process.env.PUBLIC_URL} className="avatar">
                              <img
                                src={require("../../assets/images/" +
                                  comment.image)}
                                alt="#"
                              />
                            </a>
                            <div className="post-body">
                              <div className="comment-header">
                                <span className="author">
                                  <a href={process.env.PUBLIC_URL}>
                                    {comment.title}
                                  </a>
                                </span>
                                <span className="time-ago">
                                  <a href={process.env.PUBLIC_URL}>
                                    3 months ago
                                  </a>
                                </span>
                              </div>
                              <div className="post-message">
                                <p>{comment.text}</p>
                              </div>
                              <div className="comment-footer">
                                <span className="reply">
                                  <a href={process.env.PUBLIC_URL}>
                                    <i className="ion-reply"></i> Reply
                                  </a>
                                </span>
                              </div>
                            </div>
                          </div>
                          {comment.reply.length > 0
                            ? comment.reply.map((reply) => (
                                <ul className="reply-comment" key={reply.id}>
                                  <li className="post-comment">
                                    <div className="comment-content">
                                      <a
                                        href={process.env.PUBLIC_URL}
                                        className="avatar"
                                      >
                                        <img
                                          src={require("../../assets/images/" +
                                            reply.image)}
                                          alt={process.env.PUBLIC_URL}
                                        />
                                      </a>
                                      <div className="post-body">
                                        <div className="comment-header">
                                          <span className="author">
                                            <a href={process.env.PUBLIC_URL}>
                                              {reply.title}
                                            </a>
                                          </span>
                                          <span className="time-ago">
                                            <a href={process.env.PUBLIC_URL}>
                                              18 days ago
                                            </a>
                                          </span>
                                        </div>
                                        <div className="post-message">
                                          <p>{reply.text}</p>
                                        </div>
                                        <div className="comment-footer">
                                          <span className="reply">
                                            <a href="!#">
                                              <i className="ion-reply"></i>
                                              Reply
                                            </a>
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                </ul>
                              ))
                            : null}
                        </li>
                      ))
                    : "There are no comments yet."}
                </ul>
                <div id="respond" className="comment-respond">
                  <h2 id="reply-title" className="comment-reply-title">
                    Leave a Comment
                  </h2>
                  <form
                    method="post"
                    id="form-comments"
                    className="comment-form contact-form-style-01"
                  >
                    <div className="row-form row">
                      <div className="col-form col-md-6">
                        <div className="form-group">
                          <input
                            type="text"
                            name="name"
                            className="md-input"
                            id="name"
                            required=""
                            placeholder="Name *"
                            data-error="Your Name is Required"
                          />
                        </div>
                      </div>
                      <div className="col-form col-md-6">
                        <div className="form-group">
                          <input
                            type="email"
                            name="email"
                            className="md-input"
                            id="email"
                            placeholder="Email*"
                            required=""
                            data-error="Please Enter Valid Email"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <textarea
                        name="message"
                        className="md-textarea"
                        id="message"
                        rows="7"
                        placeholder="Your Comments"
                        required=""
                        data-error="Please, Leave us a message"
                      ></textarea>
                    </div>

                    <p className="form-submit">
                      <button className="btn btn-color btn-md btn-default remove-margin">
                        Post Comments
                      </button>
                      <input type="hidden" name="comment_post_ID" />
                      <input
                        type="hidden"
                        name="comment_parent"
                        id="comment_parent"
                        value="0"
                      />
                    </p>
                  </form>
                </div>
              </div>
            </div>
            <div>
              <a href="https://bamsmackpow.com/2022/01/02/stranger-things-season-4-is-not-coming-to-netflix-in-november-2021/">
                <img src={imgStAd} alt="stranger things" />
              </a>
              <a href="https://bamsmackpow.com/2022/01/02/death-of-doctor-strange-heres-who-killed-the-sorcerer-supreme/">
                <img src={imgDrAd} alt="dr. strange" />
              </a>
              <a href="https://bamsmackpow.com/2022/01/02/is-the-walking-dead-new-tonight-october-31-2021/">
                <img src={imgWdAd} alt="walking dead" />
              </a>
            </div>
          </div>
          <BlogSidebar />
        </div>
      </div>
    </section>
  );
};

export default BlogContent;
